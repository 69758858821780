import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Flex } from '@fluentui/react-northstar';
import { getActiveDirectoryUsers, createOnlineMeeting } from '../../utils/requestHelper';
import moment from 'moment';
import './meeting.css';

import MeetingForm from './meetingForm';
import MeetingLink from './meetingLink';
import MeetingError from './meetingError';
import Loading from './loading';

const Frame = (context) => {
  const { t } = useTranslation();
  const [ show, setShow ] = useState({loading: false, link: false, form: true, error: false});
  const [ meeting, setMeeting ] = useState({subject: 'Microsoft Teams Meeting', organizer: null, participants: [], date: moment(new Date()).format('YYYY-MM-DD'), startDateTime: moment(new Date()).format('HH:mm'), endDateTime: ''});
  const [ response, setResponse ] = useState({})
  const [ errors, setErrors ] = useState({subject: false, date: false, startDateTime: false, endDateTime: false});

  useEffect(()=> {
    if(context.context)
      setMeeting(prevData => ({
        ...prevData,
        organizer: {id:context.context.loginHint, mail:context.context.loginHint}
      }))
  },[context])

  useEffect(() => {
    if(meeting.startDateTime !== ''){
      let endDateTime = new moment(`${meeting.date} ${meeting.startDateTime}`, "YYYY-MM-DD HH:mm")
      .add(60, 'minutes')
      .format('HH:mm');
      setMeeting(prevData => ({
        ...prevData,
        endDateTime: endDateTime
      }))
    }
  },[meeting.startDateTime,meeting.date]);

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setMeeting(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnSelectOrganiser = (e) => {
    setMeeting(prevData => ({
      ...prevData,
      organizer: {id:e.userPrincipalName, mail:e.mail}
    }))
  }

  const handleOnSelectParticipant = (e) => {
    let array = meeting.participants
    array.push({id:e.userPrincipalName, mail:e.mail})
    setMeeting(prevData => ({
      ...prevData,
      participants: array
    }))
  }

  const handleOnRemoveParticipant = (e) => {
    let array = meeting.participants.filter(element=> element.mail !== e.mail)
    setMeeting(prevData => ({
      ...prevData,
      participants: array
    }))
  }

  const validate = () => {
    let verificationState = true
    var errors = {
      subject: false,
      startDateTime: false,
      endDateTime: false,
      organizer: false,
      date: false,
    }

    if(!meeting.subject || meeting.subject.length === 0){
      errors.subject = true
      verificationState = false
    }
    if(!meeting.startDateTime || meeting.startDateTime.length === 0){
      errors.startDateTime = true
      verificationState = false
    }
    if(!meeting.endDateTime || meeting.endDateTime.length === 0){
      errors.endDateTime = true
      verificationState = false
    }
    if(!meeting.date || meeting.date.length === 0){
      errors.date = true
      verificationState = false
    }
    if(!meeting.organizer || meeting.organizer === ''){
      errors.organizer = true
      verificationState = false
    }
    setErrors({
      ...errors
    })
    return verificationState
    
  }
  const handleOnCreateMeeting = async () => {
    if(validate()) {
      let startDateTime = new moment.utc(new moment(`${meeting.date} ${meeting.startDateTime}`, "YYYY-MM-DD HH:mm"));
      let endDateTime = new moment.utc(new moment(`${meeting.date} ${meeting.endDateTime}`, "YYYY-MM-DD HH:mm"));
      setShow(prevData => ({
        ...prevData,
        form: false,
        loading: true
      }))
      try{
        let result = await createOnlineMeeting({subject: meeting.subject, organizer: meeting.organizer, date: meeting.date, startDateTime: startDateTime.format(), endDateTime: endDateTime.format(), participants: meeting.participants})
        console.log(result)
        if(result.success === true){
          setResponse(result.data)
          setShow(prevData => ({
            ...prevData,
            form: false,
            loading: false,
            link: true
          }))
        }
        else {
          setShow(prevData => ({
            ...prevData,
            form: false,
            loading: false,
            link: false,
            error: true
          }))
        }
      }
      catch (err) {
        setShow(prevData => ({
          ...prevData,
          form: false,
          loading: false,
          link: false,
          error: true
        }))
      }
    }
  }

  return(
    <Flex 
      column
      fill
      hAlign="center" 
      vAlign="center"
      padding="padding.medium"
    >
      <div style={{width: '100%'}}>
        <div>
          <h3 style={{textAlign: 'center'}}>{t('online_meeting-headline')}</h3>
          <h6 style={{textAlign: 'center'}}>{t('online_meeting-content')}</h6>
        </div>
        <Loading
          show={show.loading}
          content={t('online_meeting-loading')}
        />
        <MeetingError 
          show={show.error}
          title={t('online_meeting-error-headline')}
          content={t('online_meeting-error-content')}
          buttonText={t('button-label-restart')}
        />
        <MeetingLink
          show={show.link}
          joinWebUrl={response.joinWebUrl}
          tollNumber={response.audioConferencing && response.audioConferencing.tollNumber}
          conferenceId={response.audioConferencing && response.audioConferencing.conferenceId}
          dialinUrl={response.audioConferencing && response.audioConferencing.dialinUrl}
          videoTeleconferenceId={response.videoTeleconferenceId && response.videoTeleconferenceId}
        />
        <MeetingForm
          show={show.form}
          handleOnChange={handleOnChange}
          handleOnSelectParticipant={handleOnSelectParticipant}
          handleOnRemoveParticipant={handleOnRemoveParticipant}
          handleOnSelectOrganiser={handleOnSelectOrganiser}
          options={getActiveDirectoryUsers}
          organizer={meeting.organizer}
          subject={meeting.subject}
          date={meeting.date}
          startDateTime={meeting.startDateTime}
          endDateTime={meeting.endDateTime}
          participants={meeting.participants}
          onCreateClick={handleOnCreateMeeting}
          errors={errors}
        />
      </div>
    </Flex>
  );
}

export default Frame;